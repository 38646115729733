import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { selectAllMaintenancesIds, selectValidTransferredVehiclesMaintenances } from '@store/selectors/maintenances.selectors';
import { StoreState } from '@store/state/store.state';
import { selectWorkshopVerifiedMaintenances } from '@store/selectors/workshop.selectors';
import { ToastService } from '@core/services/presentable-services/toast/toast.service';

/**
 * Can activate route if maintenance exists
 */
export function detailMaintenanceGuard(route: ActivatedRouteSnapshot): CanActivateFn {
  /** Dependencies */
  const store = inject(Store<StoreState>);
  const router = inject(Router);
  const toastService = inject(ToastService);

  const canActivate$: CanActivateFn = store
    .select(selectAllMaintenancesIds)
    .pipe(
      withLatestFrom(
        store.select(selectWorkshopVerifiedMaintenances),
        store.pipe(selectValidTransferredVehiclesMaintenances),
      ),
      take(1),
      map(([maintenancesIds, verifiedMaintenances, transferredVehiclesMaintenances]) => {
        const { maintenanceId } = route.params;

        // Check if maintenance if exists on different maintenances list
        const isMaintenanceIdExists = maintenancesIds.includes(maintenanceId)
          || verifiedMaintenances.some(m => m.id === maintenanceId)
          || transferredVehiclesMaintenances.some(m => m.id === maintenanceId);

        if (!isMaintenanceIdExists) {
          toastService.presentInfo('ERROR.MAINTENANCE-NOT-FOUND');
          return router.parseUrl('maintenances');
        }
        return true;
      })
    ) as unknown as CanActivateFn;

  return canActivate$;
}
