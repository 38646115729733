import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, map } from 'rxjs';
import { selectValidUser } from '@store/selectors/user.selectors';
import { StoreState } from '@store/state/store.state';
import { ToastService } from '../services/presentable-services/toast/toast.service';
import { CanActivateFn } from '@angular/router';

export function activatedAccountGuard(): CanActivateFn {
  /** Dependencies */
  const store = inject(Store<StoreState>);
  const toastService = inject(ToastService);

  const canActivate$: CanActivateFn = store.pipe(
    selectValidUser,
    take(1),
    map(user => {
      const canActivate = user.accountData.isAccountActivated;
      if (!canActivate) {
        toastService.presentInfo('USER.ACTIVATE-ACCOUNT');
      }
      return canActivate;
    })
  ) as unknown as CanActivateFn;

  return canActivate$;
}
