import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { selectValidUser } from '@store/selectors/user.selectors';
import { StoreState } from '@store/state/store.state';
import { UserTypes } from '@core/models/user.model';

/**
 * Can activate if route is configured by user type
 */
export function userTypeGuard(userType: UserTypes, allowFleet?: boolean) {
  return function(): CanActivateFn {
    /** Dependencies */
    const store = inject(Store<StoreState>);
    const router = inject(Router);

    const canActivate$: CanActivateFn = store.pipe(
      selectValidUser,
      take(1),
      map(user => {
        const fallbackRoute = user?.accountData?.userType === 'client' ? 'vehicles' : 'clients';
        const canActivateFleet: boolean = user?.accountData?.isFleet && allowFleet;
        return user?.accountData?.userType === userType || canActivateFleet ? true : router.parseUrl(fallbackRoute);
      })
    ) as unknown as CanActivateFn;

    return canActivate$;
  };
}
