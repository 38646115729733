import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { fetchVehicle } from '@store/actions/vehicles.actions';
import { selectUserType } from '@store/selectors/user.selectors';
import { selectVehicleFromMaintenanceId } from '@store/selectors/vehicles.selectors';
import { StoreState } from '@store/state/store.state';
import { Observable, map, withLatestFrom } from 'rxjs';

export function detailMaintenanceResolver(route: ActivatedRouteSnapshot): Observable<boolean> {
  /** Dependencies */
  const store = inject(Store<StoreState>);

  const { maintenanceId } = route.params;
  return store
    .select(selectVehicleFromMaintenanceId(maintenanceId))
    .pipe(
      withLatestFrom(store.select(selectUserType)),
      map(([vehicle, userType]) => {
        if (userType === 'client') { return true; }
        if (vehicle?.isTransferredVehicle) { return true; }
        if (!vehicle?.maintenancesIds.includes(maintenanceId)) { return true; }
        if (vehicle?.maintenances.length === vehicle?.maintenancesIds.length) { return true; }

        // If vehicle has no all maintenances data request complete vehicle
        store.dispatch(fetchVehicle({ vehicleId: vehicle?.id }));
        return true;
      }),
    );
}
