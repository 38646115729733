import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { StoreState } from '@store/state/store.state';
import { FilesFolder, FolderEntityTypes } from '@core/models/files-folder.model';
import { selectMaintenance } from '@store/selectors/maintenances.selectors';
import { selectValidUserTransferredVehicle, selectValidUserTransferredVehicles, selectVehicle } from '@store/selectors/vehicles.selectors';
import { Observable } from 'rxjs';

/**
 * Can activate if folder exists
 */
export function filesFolderGuard(route: ActivatedRouteSnapshot): CanActivateFn {
  /** Dependencies */
  const router = inject(Router);

  const { folderId, entityType, entityId } = route.params;
  const selector = getSelectorFromEntityType(entityType, entityId);

  const canActivate$: CanActivateFn = selector.pipe(
    take(1),
    map((folders) => {
      const detailUrl = router.parseUrl(`/${entityType}-detail/${entityId}`);
      // If folder does not exists redirect to entity detail
      return folders.some(folder => folder.id === folderId) || detailUrl;
    })
  ) as unknown as CanActivateFn;

  return canActivate$;
}

/**
 * Get selector from entity type
 */
function getSelectorFromEntityType(entityType: FolderEntityTypes, entityId: string): Observable<FilesFolder[]> {
  const store = inject(Store<StoreState>);
  switch (entityType) {
  case 'vehicle':
    return store
      .select(selectVehicle(entityId))
      .pipe(map(vehicle => vehicle.filesFolders));
  case 'maintenance':
    return store
      .select(selectMaintenance(entityId))
      .pipe(map((maintenance => maintenance.filesFolders)));
  case 'transferredVehicle':
    return store
      .pipe(
        selectValidUserTransferredVehicle(entityId),
        map(vehicle => vehicle.filesFolders)
      );
  case 'transferredVehicleMaintenance':
    return store
      .pipe(
        selectValidUserTransferredVehicles,
        map(vehicles => vehicles
          .find(v => v.maintenances.find(m => m.id === entityId))
          ?.maintenances?.find(m => m.id === entityId).filesFolders)
      );
  default:
    return null;
  }
}
