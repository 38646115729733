import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, map } from 'rxjs';
import { selectValidUser } from '@store/selectors/user.selectors';
import { StoreState } from '@store/state/store.state';
import { CanActivateFn, Router } from '@angular/router';

export function subscriptionGuard(): CanActivateFn {
  /** Dependencies */
  const store = inject(Store<StoreState>);
  const router = inject(Router);

  const canActivate$: CanActivateFn = store.pipe(
    selectValidUser,
    take(1),
    map((user) => {
      const canActivate = user?.accountData?.isPremium;
      if (!canActivate) {
        return router.parseUrl('user');
      }
      return canActivate;
    })
  ) as unknown as CanActivateFn;

  return canActivate$;
}
