import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LS_KEYS, StorageService } from '../services/storage/storage.service';

export function loginGuard(): CanActivateFn {
  /** Dependencies */
  const storageService = inject(StorageService);
  const router = inject(Router);

  const token = storageService.getItem(LS_KEYS.TOKEN);

  return (token ? router.parseUrl('clients') : true) as unknown as CanActivateFn;
}
