import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { DevicePlatform } from '@core/models/app.model';

export function platformGuard(platform: DevicePlatform) {
  return function(): CanActivateFn {
    /** Dependencies */
    const router = inject(Router);
  
    // If platform is not valid, redirect to clients
    const isValidPlatform = Capacitor.getPlatform() === platform;
    if (!isValidPlatform) {
      return router.parseUrl('clients') as unknown as CanActivateFn;
    }
  
    // If platform is web, activate route
    return true as unknown as CanActivateFn;
  };
}
