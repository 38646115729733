import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { selectValidUserTransferredVehicles, selectValidVehicles } from '@store/selectors/vehicles.selectors';
import { StoreState } from '@store/state/store.state';

/**
 * Can activate route if vehicle exists
 */
export function detailVehicleGuard(route: ActivatedRouteSnapshot): CanActivateFn {
  /** Dependencies */
  const store = inject(Store<StoreState>);
  const router = inject(Router);

  const canActivate$: CanActivateFn = store.pipe(
    selectValidVehicles,
    withLatestFrom(store.pipe(selectValidUserTransferredVehicles)),
    take(1),
    map(([vehicles, transferredVehicles]) => {
      const vehicleId = route.params.vehicleId;
      if (!vehicleId) { return true; }
      const isVehicleIdExists = vehicles.some(v => v.id === vehicleId) || transferredVehicles.some(v => v.id === vehicleId);
      return isVehicleIdExists ? true : router.parseUrl('vehicles');
    })
  ) as unknown as CanActivateFn;

  return canActivate$;
}
