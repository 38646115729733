import { Routes } from '@angular/router';
import { authGuard } from '@core/guards/auth.guard';
import { detailMaintenanceGuard } from '@core/guards/detail-maintenance.guard';
import { detailVehicleGuard } from '@core/guards/detail-vehicle.guard';
import { loginGuard } from '@core/guards/login.guard';
import { activatedAccountGuard } from '@core/guards/activated-account.guard';
import { filesFolderGuard } from '@core/guards/files-folder.guard';
import { userTypeGuard } from '@core/guards/user-type.guard';
import { subscriptionGuard } from '@core/guards/subscription.guard';
import { UserTypes } from '@core/models/user.model';
import { calculateIsTabletOrBigger } from '@helpers/common.helpers';
import { detailMaintenanceResolver } from '@core/resolvers/detail-maintenance.resolver';
import { detailVehicleResolver } from '@core/resolvers/detail-vehicle.resolver';
import { platformGuard } from '@core/guards/platform.guard';

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => calculateIsTabletOrBigger(window.innerWidth, window.innerHeight) ? MAIN_ROUTES : import('@core/components/tabs/tabs.routes').then(r => r.TABS_ROUTES),
    canActivate: [
      authGuard,
    ]
  },
  {
    path: 'login',
    loadComponent: () => import('@pages/login/login.page').then(p => p.LoginPage),
    canActivate: [
      loginGuard,
    ],
    data: {
      name: 'login',
    },
  },
  {
    path: 'signup',
    loadComponent: () => import('@pages/sign-up/sign-up.page').then(p => p.SignUpPage),
    canActivate: [
      loginGuard,
    ],
    data: {
      name: 'signup',
    }
  },
  {
    path: 'detail-vehicle/:vehicleId',
    loadComponent: () => import('@pages/detail-vehicle/detail-vehicle.page').then(p => p.DetailVehiclePage),
    canActivate: [
      authGuard,
      detailVehicleGuard,
    ],
    data: {
      name: 'vehicleDetail',
      preload: true,
    },
    resolve: {
      requestedVehicle: detailVehicleResolver,
    }
  },
  {
    path: 'detail-vehicle/:vehicleId/maintenances',
    loadComponent: () => import('@pages/maintenances/maintenances.page').then(p => p.MaintenancesPage),
    canActivate: [
      authGuard,
    ],
    data: {
      name: 'vehicleMaintenances',
      preload: true,
    }
  },
  {
    path: 'detail-maintenance/:maintenanceId',
    loadComponent: () => import('@pages/detail-maintenance/detail-maintenance.page').then(p => p.DetailMaintenancePage),
    canActivate: [
      authGuard,
      detailMaintenanceGuard,
    ],
    resolve: {
      requestedVehicle: detailMaintenanceResolver,
    },
    data: {
      name: 'maintenanceDetail',
      preload: true,
    }
  },
  {
    path: 'detail-workshop/:workshopId',
    loadComponent: () => import('@pages/detail-workshop/detail-workshop.page').then(p => p.DetailWorkshopPage),
    canActivate: [
      authGuard,
    ],
    data: {
      name: 'workshopDetail',
    }
  },
  {
    path: 'detail-client/:clientId',
    loadComponent: () => import('@pages/detail-client/detail-client.page').then(p => p.DetailClientPage),
    canActivate: [
      authGuard,
    ],
    data: {
      name: 'clientDetail',
    }
  },
  {
    path: 'subscription',
    loadComponent: () => import('@pages/subscription/subscription.page').then(p => p.SubscriptionPage),
    canActivate: [
      activatedAccountGuard,
      authGuard,
      subscriptionGuard,
    ],
    data: {
      name: 'subscription',
      preload: true,
    }
  },
  {
    path: 'settings',
    loadComponent: () => import('@pages/settings/settings.page').then(p => p.SettingsPage),
    canActivate: [
      authGuard,
    ],
    data: {
      name: 'settings',
    }
  },
  {
    path: 'files-folder/:folderId/:entityType/:entityId',
    loadComponent: () => import('@pages/files-folder/files-folder.page').then(p => p.FilesFolderPage),
    canActivate: [
      activatedAccountGuard,
      authGuard,
      filesFolderGuard,
    ],
    data: {
      name: 'filesFolder',
      preload: true,
    },
  },
  {
    path: 'verified-maintenances',
    loadComponent: () => import('@pages/workshop-verified-maintenances/workshop-verified-maintenances.page').then(p => p.WorkshopVerifiedMaintenancesPage),
    canActivate: [
      authGuard,
      userTypeGuard('workshop'),
    ],
    data: {
      name: 'workshopVerifiedMaintenances',
      preload: true,
      preloadUserType: 'workshop' as UserTypes,
    }
  },
  {
    path: 'generated-invoices',
    loadComponent: () => import('@pages/workshop-generated-invoices/workshop-generated-invoices.page').then(p => p.WorkshopGeneratedInvoicesPage),
    canActivate: [
      authGuard,
      userTypeGuard('workshop'),
    ],
    data: {
      name: 'workshopGeneratedInvoices',
      preload: true,
      preloadUserType: 'workshop' as UserTypes,
    }
  },
  {
    path: 'stock',
    loadComponent: () => import('@pages/workshop-stock/workshop-stock.page').then(p => p.WorkshopStockPage),
    canActivate: [
      authGuard,
      userTypeGuard('workshop'),
    ],
    data: {
      name: 'workshopStock',
      preload: true,
      preloadUserType: 'workshop' as UserTypes,
    }
  },
  {
    path: 'news',
    loadComponent: () => import('@pages/news/news.page').then(r => r.NewsPage),
    canActivate: [
      authGuard,
    ],
    data: {
      name: 'news',
      preload: true,
      preloadUserType: 'client' as UserTypes,
    }
  },
  {
    path: 'workshop/:username',
    loadComponent: () => import('@pages/workshop-landing/workshop-landing.page').then(r => r.WorkshopLandingPage),
    canActivate: [
      platformGuard('web'),
    ],
    data: {
      name: 'workshop-landing',
      landingPageMode: true,
    }
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

export const MAIN_ROUTES: Routes = [
  {
    path: 'clients',
    loadComponent: () => import('@pages/workshop-clients/workshop-clients.page').then(r => r.WorkshopClientsPage),
    canActivate: [
      authGuard,
      userTypeGuard('workshop'),
    ],
    data: {
      name: 'clients',
      preload: true,
      preloadUserType: 'workshop' as UserTypes,
    }
  },
  {
    path: 'schedule',
    loadComponent: () => import('@pages/schedule/schedule.page').then(r => r.SchedulePage),
    canActivate: [
      authGuard,
      userTypeGuard('workshop', true),
    ],
    data: {
      name: 'schedule',
      preload: true,
    },
  },
  {
    path: 'vehicles',
    loadComponent: () => import('@pages/vehicles/vehicles.page').then(r => r.VehiclesPage),
    canActivate: [
      authGuard,
    ],
    data: {
      name: 'vehicles',
      preload: true,
    },
  },
  {
    path: 'maintenances',
    loadComponent: () => import('@pages/maintenances/maintenances.page').then(r => r.MaintenancesPage),
    canActivate: [
      authGuard,
      userTypeGuard('client'),
    ],
    data: {
      name: 'maintenances',
      preload: true,
      preloadUserType: 'client' as UserTypes,
    },
  },
  {
    path: 'refuelings',
    loadComponent: () => import('@pages/refuelings/refuelings.page').then(p => p.RefuelingsPage),
    canActivate: [
      authGuard,
      userTypeGuard('client'),
    ],
    data: {
      name: 'refuelings',
      preload: true,
      preloadUserType: 'client' as UserTypes,
    },
  },
  {
    path: 'user',
    loadComponent: () => import('@pages/user/user.page').then(r => r.UserPage),
    canActivate: [
      authGuard,
    ],
    data: {
      name: 'user',
      preload: true,
    }
  },
  {
    path: '',
    redirectTo: '/clients',
    pathMatch: 'full'
  }
];
