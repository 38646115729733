import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { fetchUser, logout } from '@store/actions/user.actions';
import { StoreState } from '@store/state/store.state';
import { LS_KEYS, StorageService } from '../services/storage/storage.service';

export function authGuard(route: ActivatedRouteSnapshot): CanActivateFn {
  /** Dependencies */
  const store = inject(Store<StoreState>);
  const router = inject(Router);
  const storageService = inject(StorageService);

  const tokenParam = route.queryParams.token as string;
  // If token is in url, set to local storage and fetch user and activate route
  if (tokenParam) {
    storageService.setItem(LS_KEYS.TOKEN, tokenParam);
    store.dispatch(fetchUser({ showLoading: true }));
    const path = route.routeConfig.path;
    return router.parseUrl(path) as unknown as CanActivateFn;
  }

  // If token is in local storage, activate route
  const token = storageService.getItem(LS_KEYS.TOKEN);
  if (!token) {
    store.dispatch(logout({ shouldLogoutOnApi: false }));
    return false as unknown as CanActivateFn;
  }
  // If token is in local storage, activate route
  return true as unknown as CanActivateFn;
}
